import { COLOR_CONSTANTS } from './index'

export const colors = {
  primary: COLOR_CONSTANTS.COBALT,
  primaryText: COLOR_CONSTANTS.NAVY,
  secondaryText: COLOR_CONSTANTS.SAPPHIRE,
  ternaryText: COLOR_CONSTANTS.VENUS_MIST,
  denimText: COLOR_CONSTANTS.DENIM,
  white: COLOR_CONSTANTS.WHITE,
  primary_text_reverted: COLOR_CONSTANTS.WHITE,
  error: COLOR_CONSTANTS.FLUORESCENT_RED,
  warning: COLOR_CONSTANTS.PUMPKIN_PIE,
  success: COLOR_CONSTANTS.JUNGLE_GREEN,
  background: COLOR_CONSTANTS.DAISY,
  background_card: COLOR_CONSTANTS.WHITE,
  background_card_secondary: COLOR_CONSTANTS.WHITE,
  background_modal_overlay: COLOR_CONSTANTS.SALUTE,
  background_modal_header: COLOR_CONSTANTS.BOYSENBERRY_SHADOW,
  background_modal: COLOR_CONSTANTS.WHITE,
  background_item_hover: COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL,

  cliploader_color: COLOR_CONSTANTS.COBALT,

  border_color: COLOR_CONSTANTS.SOLITUDE,
  border_color_hover: COLOR_CONSTANTS.NAVY,
  border_color_light: COLOR_CONSTANTS.SALT,
  border_color_light_hover: COLOR_CONSTANTS.CERULEAN,

  background_badge_active: COLOR_CONSTANTS.JUNGLE_GREEN,
  background_badge_inactive: COLOR_CONSTANTS.COSMIC_ENERGY,

  background_slider: COLOR_CONSTANTS.DAISY,
  background_item_light: COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL,

  input_placeholder_color: COLOR_CONSTANTS.COSMIC_ENERGY,
  icon_color_gray: COLOR_CONSTANTS.COSMIC_ENERGY,
  icon_background: COLOR_CONSTANTS.WHITE,
  icon_background_hover: COLOR_CONSTANTS.DAISY,

  box_shadow: COLOR_CONSTANTS.SALUTE,

  background_loading_progress: COLOR_CONSTANTS.DAISY,

  progress_line_background: COLOR_CONSTANTS.SPRUCE,
  progress_line_color: COLOR_CONSTANTS.COBALT,

  composer_icons_color: COLOR_CONSTANTS.GLACIER_PEARL,
  composer_variable_color: COLOR_CONSTANTS.ROCKMAN_BLUE,
  composer_selected_text_range_color: COLOR_CONSTANTS.COSMIC_ENERGY,

  background_internal_textarea: COLOR_CONSTANTS.CARROT,

  text_slash_divider_color: COLOR_CONSTANTS.VIOLET_WHIMSEY,

  switch_component_background_on: COLOR_CONSTANTS.COBALT,
  switch_component_background_off: COLOR_CONSTANTS.COSMIC_ENERGY,

  primary_button_border: COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL, // border of primary/gradient button
  primary_button_background: 'linear-gradient(90deg, #0b60da 0%, #2482fd 100%)', // background of primary/gradient button
  gray_button_background: COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL, // background of gray button
  gray_button_color: COLOR_CONSTANTS.SALUTE, // text color inside of gray button

  dashboard_statistics_alerts_text_color: COLOR_CONSTANTS.CERULEAN, // info color on dashboard texts in the right section

  checkbox_background: COLOR_CONSTANTS.WHITE,
  checkbox_color: COLOR_CONSTANTS.NAVY,

  inbox_default_background_message_color: COLOR_CONSTANTS.SALT,
  inbox_for_reply_background_message_color: COLOR_CONSTANTS.DAISY,
  inbox_internal_background_message_color: COLOR_CONSTANTS.CARROT,
  inbox_own_background_message_color: COLOR_CONSTANTS.COBALT,

  inbox_default_primary_text_message_color: COLOR_CONSTANTS.VENUS_MIST,
  inbox_default_secondary_text_message_color: COLOR_CONSTANTS.VENUS_MIST,
  inbox_for_reply_primary_text_message_color: COLOR_CONSTANTS.VENUS_MIST,
  inbox_for_reply_secondary_text_message_color: COLOR_CONSTANTS.VENUS_MIST,
  inbox_internal_primary_text_message_color: COLOR_CONSTANTS.VENUS_MIST,
  inbox_internal_secondary_text_message_color: COLOR_CONSTANTS.VENUS_MIST,
  inbox_own_primary_text_message_color: COLOR_CONSTANTS.WHITE,
  inbox_own_secondary_text_message_color: COLOR_CONSTANTS.WHITE,
}
